<template>
  <div>
    <b-modal
      id="modal-edit"
      size="lg"
      centered
      :title="$store.state.modal.editAsuransi"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group :label="$store.state.form.asuransi" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('namaAsuransi')"
            v-model="$v.data.namaAsuransi.$model"
            v-on:keydown.enter.prevent="simpan()"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit')">
          {{$store.state.form.batal}}
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {mapGetters} from "vuex"
export default {
  name: "modalEdit",
  props: ["data"],
  data() {
    return {
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    ...mapGetters(["lang"]),
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      namaAsuransi: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      if (vm.isValid) {
        vm.busy = true;
        vm.button = vm.$store.state.form.tunggu;
        axios
          .post(ipBackend + "masterAsuransi/update", vm.data)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = vm.$store.state.form.simpan;
              vm.busy = false;
              this.$v.$reset()
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENGUBAH DATA MASTER ASURANSI",
                showing: true,
              });
              this.$bvModal.hide("modal-edit");
            } else {
              vm.button = vm.$store.state.form.simpan;
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            vm.button = vm.$store.state.form.simpan;
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
  },
  watch:{
    lang:function(val){
      this.button = this.$store.state.form.simpan
    }
  }
};
</script>
